<template>
  <div id="applySuccess">
    <header-nav :content="`${$t('pages_router_003')} / ${ $t('pages_router_005')}`" />
    <div class="common-card-body">
      <a-steps :current="3" size="small" class="apply-step">
        <a-step :title="$t('pages_receive_016')" />
        <a-step :title="$t('pages_receive_017')" />
        <a-step :title="$t('pages_receive_018')" />
      </a-steps>
      <div class="apply-result">
        <div class="title flex-align">
          <check-circle-outlined class="ic-success" />
          <span>{{ $t('pages_receive_051') }}</span>
        </div>
        <div class="desc">
          <span>{{ $t('pages_receive_052') }}</span>
          <span
            class="link-line"
            @click="$router.replace('/receive/account')"
          >{{ $t('pages_receive_053') }}</span>
          <span>{{ $t('pages_receive_054') }}</span>
        </div>
        <div class="edit">
          <a-button
            shape="round"
            type="primary"
            class="success-btn"
            @click="$router.replace('/receive/apply_type')"
          >{{ $t('common_text_010') }}</a-button>
          <a-button
            shape="round"
            style="margin-left: 18px"
            class="success-btn"
            type="primary"
            ghost
            @click="backReturn"
          >{{ $t('common_text_003') }}</a-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderNav from '@/components/header-nav'
import { useRouter, useRoute } from 'vue-router'
export default {
  name: 'ApplySuccess',
  components: {
    'header-nav': HeaderNav
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const backReturn = () => {
      if (route.query.p && route.query.p === 'va') {
        router.replace('/home/index')
      } else {
        router.back(-1)
      }
    }
    return {
      backReturn, router, route
    }
  }
}
</script>
<style lang="less">
#applySuccess {
  .common-card-body {
    padding: 18px 36px 64px 36px;
    .apply-result {
      margin-top: 60px;
      .title {
        justify-content: center;
        font-size: 24px;
        font-weight: 500;
        .ic-success {
          color: #02A900;
          margin-right: 8px;
          font-size: 28px;
        }
      }
      .desc {
        margin-top: 32px;
        .link-line {
          color: @main-color;
          padding: 0 2px;
          cursor: pointer;
        }
      }
      .edit {
        margin-top: 36px;
        .success-btn {
          min-width: 138px;
        }
      }
    }
  }
}
</style>
